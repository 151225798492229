<template>
  <ValidationObserver v-slot="{ handleSubmit, errors }">
    <form @submit.prevent="handleSubmit(submit)">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">General</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>PLACA PATENTE</label>
                <md-input type="text" disabled :value="currIca.placa_patente">
                </md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <ValidationProvider
                name="servicio"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="servicio">SERVICIO</label>
                  <md-select
                    name="servicio"
                    id="servicio"
                    v-model="servicio"
                    vid="servicio"
                    :disabled="submited"
                  >
                    <md-option value=""></md-option>
                    <md-option
                      v-for="option in unServicio"
                      :key="option.toUpperCase()"
                      :value="option.toUpperCase()"
                      >{{ option.toUpperCase() }}</md-option
                    >
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>ESTANDAR</label>
                <md-input type="text" disabled :value="currIca.estandar">
                </md-input>
              </md-field>
            </div>
          </div>
        </md-card-content>
      </md-card>

      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Criterios</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <md-checkbox v-model="A16"
                >A16: Se retira información campaña "Ingreso solo por puerta
                trasera"</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-100">
              <md-checkbox v-model="A17"
                >A17: Se instala nueva señalética "Siempre usa mascarilla",
                "Entre todos podemos evitar rebrotes"</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-100">
              <md-checkbox v-model="A09"
                >A09: Los letreros de recorrido están en buen estado, bien
                ubicados y exhiben información correcta respecto del sentido del
                servicio</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-100">
              <md-checkbox v-model="A10"
                >A10: El panel supervisor variable está encendido, en buen
                estado y exhibe información correcta respecto del sentido del
                servicio</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-100">
              <md-checkbox v-model="A14"
                >A14: El bus tiene funcionando el velocímetro</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-100">
              <md-checkbox v-model="A11"
                >A11: La señalización interior está correctamente
                instalada</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-100">
              <md-checkbox v-model="A12"
                >A12: La señalización interior está en buen estado</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-100">
              <md-checkbox v-model="A13"
                >A13: El bus cuenta con leyenda "Informaciones y Reclamos" bien
                ubicada y legible</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-100">
              <md-checkbox v-model="A01"
                >A01: El conductor abre y cierra oportunamente las puertas al
                finalizar e iniciar el movimiento</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-100">
              <md-checkbox v-model="A02"
                >A02: El conductor conduce sin frenazos ni movimientos
                bruscos</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-100">
              <md-checkbox v-model="A03"
                >A03: El conductor es amable con los usuarios</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-100">
              <md-checkbox v-model="A04"
                >A04: El conductor detiene el bus cuando debe, es decir, cuando
                algún usuario requiere subir o bajar</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-100">
              <md-checkbox v-model="A05"
                >A05: El conductor detiene el bus donde debe, es decir, sólo en
                paradas autorizadas</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-100">
              <md-checkbox v-model="A06"
                >A06: El conductor aproxima el bus correctamente al paradero,
                sin detenerse en segunda fila o lejos de la acera</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-100">
              <md-checkbox v-model="A07"
                >A07: El conductor conduce sin fumar, ni conversar por celular o
                con un pasajero o un acompañante mientras conduce</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-100">
              <md-checkbox v-model="A08"
                >A08: El conductor se detiene ante todas las luces rojas de los
                semáforos y señales Pare, señales Ceda el paso y Pasos de
                Cebra</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-100">
              <md-checkbox v-model="A18"
                >A18: Autobús manejado por un hombre</md-checkbox
              >
            </div>
          </div>
        </md-card-content>
      </md-card>
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Observaciones</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div
              v-if="!A09"
              class="md-layout-item md-size-50 md-xsmall-size-100"
            >
              <ValidationProvider
                name="A09C"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="A09C">A09 - CRITERIOS</label>
                  <md-select v-model="A09C" :disabled="submited" multiple>
                    <md-option value="A09-C1-A">
                      LETRERO FRONTAL CON SERVICIO Y/O SENTIDO INCORRECTO
                    </md-option>
                    <md-option value="A09-C2-A"
                      >LETRERO FRONTAL MAL UBICADO</md-option
                    >
                    <md-option value="A09-C3-A">
                      LETRERO FRONTAL EN MAL ESTADO
                    </md-option>
                    <md-option value="A09-C3-B">
                      INFORMACIÓN LETRERO FRONTAL NO LEGIBLE
                    </md-option>
                    <md-option value="A09-C4-A">SIN LETRERO FRONTAL</md-option>
                    <md-option value="A09-C5-A">
                      LETRERO FRONTAL ES RETIRADO EN RUTA
                    </md-option>
                    <md-option value="A09-C6-A">
                      LETRERO LATERAL CON SERVICIO Y/O SENTIDO INCORRECTO
                    </md-option>
                    <md-option value="A09-C7-A"
                      >LETRERO LATERAL MAL UBICADO</md-option
                    >
                    <md-option value="A09-C8-A">
                      LETRERO LATERAL EN MAL ESTADO
                    </md-option>
                    <md-option value="A09-C8-B">
                      INFORMACIÓN LETRERO LATERAL NO LEGIBLE
                    </md-option>
                    <md-option value="A09-C9-A">SIN LETRERO LATERAL</md-option>
                    <md-option value="A09-C10-A">
                      LETRERO LATERAL ES RETIRADO EN RUTA
                    </md-option>
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div
              v-if="!A10"
              class="md-layout-item md-size-50 md-xsmall-size-100"
            >
              <ValidationProvider
                name="A10C"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="A10C">A10 - CRITERIOS</label>
                  <md-select v-model="A10C" :disabled="submited" multiple>
                    <md-option value="A10-C1-A">SENTIDO INCORRECTO</md-option>
                    <md-option value="A10-C2-A">
                      INFORMACIÓN INCORRECTA EN PANEL SUPERIOR VARIABLE
                    </md-option>
                    <md-option value="A10-C3-A"
                      >FALTAN LUCES EN INFORMACIÓN</md-option
                    >
                    <md-option value="A10-C3-B"
                      >INFORMACIÓN NO LEGIBLE</md-option
                    >
                    <md-option value="A10-C3-C">PANEL MAL INSTALADO</md-option>
                    <md-option value="A10-C4-A">PANEL APAGADO</md-option>
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div
              v-if="!A14"
              class="md-layout-item md-size-50 md-xsmall-size-100"
            >
              <ValidationProvider
                name="A14C"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="A14C">A14 - CRITERIOS</label>
                  <md-select v-model="A14C" :disabled="submited" multiple>
                    <md-option value="A14-C1-A">VELOCÍMETRO MALO</md-option>
                    <md-option value="A14-C2-A">VELOCÍMETRO TAPADO</md-option>
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div
              v-if="!A11"
              class="md-layout-item md-size-50 md-xsmall-size-100"
            >
              <ValidationProvider
                name="A11C"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="A11C">A11 - CRITERIOS</label>
                  <md-select v-model="A11C" :disabled="submited" multiple>
                    <md-option value="A11-C1-A">
                      SIN ADHESIVOS INFORMATIVOS (BLANCOS)
                    </md-option>
                    <md-option value="A11-C1-B">
                      ADHESIVOS INFORMATIVOS MAL INSTALADOS
                    </md-option>
                    <md-option value="A11-C2-A">
                      SIN ADHESIVOS DE ADVERTENCIA (ROJOS)
                    </md-option>
                    <md-option value="A11-C2-B">
                      ADHESIVOS DE ADVERTENCIA MAL INSTALADOS
                    </md-option>
                    <md-option
                      value="A11-C3-A"
                      v-if="!removec.includes('A11-C3-A')"
                      >SIN ADHESIVOS DISCAPACITADOS (ACCESO SILLA DE
                      RUEDAS)</md-option
                    >
                    <md-option
                      value="A11-C3-B"
                      v-if="!removec.includes('A11-C3-B')"
                    >
                      ADHESIVOS DISCAPACITADOS MAL INSTALADOS (ACCESO SILLA DE
                      RUEDAS)
                    </md-option>
                    <md-option value="A11-C4-A">
                      SIN ADHESIVOS MOVILIDAD REDUCIDA (ASIENTO PREFERENTE)
                    </md-option>
                    <md-option value="A11-C4-B">
                      ADHESIVOS MOVILIDAD REDUCIDA MAL INSTALADOS (ASIENTO
                      PREFERENTE)
                    </md-option>
                    <md-option value="A11-C5-A">SIN PPU</md-option>
                    <md-option value="A11-C5-B">PPU MAL INSTALADOS</md-option>
                    <md-option value="A11-C6-A"
                      >SIN IDENTIFICACIÓN OPERADOR</md-option
                    >
                    <md-option value="A11-C6-B">
                      IDENTIFICACIÓN DEL OPERADOR MAL INSTALADAS
                    </md-option>
                    <md-option value="A11-C7-A">SIN SOAP</md-option>
                    <md-option value="A11-C7-B">SOAP MAL INSTALADOS</md-option>
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div
              v-if="!A12"
              class="md-layout-item md-size-50 md-xsmall-size-100"
            >
              <ValidationProvider
                name="A12C"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="A12C">A12 - CRITERIOS</label>
                  <md-select v-model="A12C" :disabled="submited" multiple>
                    <md-option value="A12-C1-A">ADHESIVOS DESPEGADOS</md-option>
                    <md-option value="A12-C1-B">ADHESIVOS SUCIOS</md-option>
                    <md-option value="A12-C1-C">ADHESIVOS BORRADOS</md-option>
                    <md-option value="A12-C1-D">ADHESIVOS ROTOS</md-option>
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div
              v-if="!A13"
              class="md-layout-item md-size-50 md-xsmall-size-100"
            >
              <ValidationProvider
                name="A13C"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="A13C">A13 - CRITERIOS</label>
                  <md-select v-model="A13C" :disabled="submited" multiple>
                    <md-option value="A13-C1-A"
                      >LEYENDA LATERAL MAL UBICADA</md-option
                    >
                    <md-option value="A13-C1-B">SIN LEYENDA LATERAL</md-option>
                    <md-option value="A13-C1-C">SIN PPU LATERAL</md-option>
                    <md-option value="A13-C2-A"
                      >LEYENDA TRASERA MAL UBICADA</md-option
                    >
                    <md-option value="A13-C2-B">SIN LEYENDA TRASERA</md-option>
                    <md-option value="A13-C2-C">SIN PPU TRASERA</md-option>
                    <md-option value="A13-C3-A"
                      >LEYENDA LATERAL NO LEGIBLE</md-option
                    >
                    <md-option value="A13-C3-B"
                      >LEYENDA TRASERA NO LEGIBLE</md-option
                    >
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div
              v-if="!A01"
              class="md-layout-item md-size-50 md-xsmall-size-100"
            >
              <ValidationProvider
                name="A01C"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="A01C">A01 - CRITERIOS</label>
                  <md-select v-model="A01C" :disabled="submited" multiple>
                    <md-option value="A01-C1-A" selected="selected">
                      SE DESPLAZA CON LAS PUERTAS ABIERTAS
                    </md-option>
                    <md-option value="A01-C2-A">
                      NO ABRE Y/O CIERRA LAS PUERTAS OPORTUNAMENTE
                    </md-option>
                    <md-option value="A01-C2-B">
                      ATRAPA PASAJERO CON LA PUERTA
                    </md-option>
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div
              v-if="!A02"
              class="md-layout-item md-size-50 md-xsmall-size-100"
            >
              <ValidationProvider
                name="A02C"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="A02C">A02 - CRITERIOS</label>
                  <md-select v-model="A02C" :disabled="submited" multiple>
                    <md-option value="A02-C2-A">
                      FRENA BRUSCO INNECESARIAMENTE
                    </md-option>
                    <md-option value="A02-C2-B">
                      REALIZA MOVIMIENTO BRUSCO INNECESARIO
                    </md-option>
                    <md-option value="A02-C3-A">
                      NO DISMINUYE PAULATINAMENTE LA VELOCIDAD
                    </md-option>
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div
              v-if="!A03"
              class="md-layout-item md-size-50 md-xsmall-size-100"
            >
              <ValidationProvider
                name="A03C"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="A03C">A03 - CRITERIOS</label>
                  <md-select v-model="A03C" :disabled="submited" multiple>
                    <md-option value="A03-C1-A"
                      >ES AGRESIVO CON LOS USUARIOS</md-option
                    >
                    <md-option value="A03-C3-A">
                      UTILIZA GESTOS Y/O VOCABULARIO NO ADECUADO EN SERVICIO
                    </md-option>
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div
              v-if="!A04"
              class="md-layout-item md-size-50 md-xsmall-size-100"
            >
              <ValidationProvider
                name="A04C"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="A04C">A04 - CRITERIOS</label>
                  <md-select v-model="A04C" :disabled="submited" multiple>
                    <md-option value="A04-C1-A">
                      NO SE DETIENE EN PARADA SOLICITADA PARA BAJAR
                    </md-option>
                    <md-option value="A04-C3-A">
                      NO SE DETIENE EN LA PARADA SOLICITADA PARA SUBIR
                    </md-option>
                    <md-option value="A04-C3-B">
                      NO SE DETIENE EN PARADERO OCUPADO POR OTRO BUS
                    </md-option>
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div
              v-if="!A05"
              class="md-layout-item md-size-50 md-xsmall-size-100"
            >
              <ValidationProvider
                name="A05C"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="A05C">A05 - CRITERIOS</label>
                  <md-select v-model="A05C" :disabled="submited" multiple>
                    <md-option value="A05-C1-A">
                      SE DETIENE EN PARADA NO ASIGNADA AL SERVICIO
                    </md-option>
                    <md-option value="A05-C1-B">
                      SE DETIENE EN LUGAR QUE NO ES PARADA
                    </md-option>
                    <md-option value="A05-C3-A">
                      NO ESPERA TURNO PARA DETENERSE EN LUGAR CORRECTO
                    </md-option>
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div
              v-if="!A06"
              class="md-layout-item md-size-50 md-xsmall-size-100"
            >
              <ValidationProvider
                name="A06C"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="A06C">A06 - CRITERIOS</label>
                  <md-select v-model="A06C" :disabled="submited" multiple>
                    <md-option value="A06-C1-A">
                      NO APROXIMA BUS CORRECTAMENTE A LA ACERA
                    </md-option>
                    <md-option value="A06-C2-A">
                      DEJA PASAJEROS EN SEGUNDA FILA
                    </md-option>
                    <md-option value="A06-C2-B">
                      RECOGE PASAJEROS EN SEGUNDA FILA
                    </md-option>
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div
              v-if="!A07"
              class="md-layout-item md-size-50 md-xsmall-size-100"
            >
              <ValidationProvider
                name="A07C"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="A07C">A07 - CRITERIOS</label>
                  <md-select v-model="A07C" :disabled="submited" multiple>
                    <md-option value="A07-C1-A"
                      >CONVERSA CON ACOMPAÑANTE</md-option
                    >
                    <md-option value="A07-C1-B">CONVERSA CON COLEGA</md-option>
                    <md-option value="A07-C1-C"
                      >CONVERSA CON FISCALIZADOR</md-option
                    >
                    <md-option value="A07-C1-D"
                      >CONVERSA CON PASAJERO</md-option
                    >
                    <md-option value="A07-C1-E">CONDUCTOR FUMA</md-option>
                    <md-option value="A07-C1-F">CONVERSA POR CELULAR</md-option>
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div
              v-if="!A08"
              class="md-layout-item md-size-50 md-xsmall-size-100"
            >
              <ValidationProvider
                name="A08C"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="A08C">A08 - CRITERIOS</label>
                  <md-select v-model="A08C" :disabled="submited" multiple>
                    <md-option value="A08-C1-A">NO RESPETA LUZ ROJA</md-option>
                    <md-option value="A08-C1-B"
                      >NO RESPETA SEÑAL PARE</md-option
                    >
                    <md-option value="A08-C1-C">
                      NO RESPETA SEÑAL CEDA EL PASO
                    </md-option>
                    <md-option value="A08-C1-D"
                      >NO RESPETA PASO DE CEBRA</md-option
                    >
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
          </div>
        </md-card-content>
      </md-card>
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Cierre</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <label>COMENTARIOS</label>
                <md-input
                  type="text"
                  autocomplete="off"
                  v-model="comentarios"
                  :disabled="submited"
                >
                </md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <ValidationProvider
                name="lugar_termino"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>LUGAR TERMINO</label>
                  <md-input
                    type="text"
                    autocomplete="off"
                    v-model="lugar_termino"
                    :disabled="submited"
                    @keydown.space.prevent
                  >
                  </md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
          </div>
        </md-card-content>

        <md-card-actions>
          <span class="md-helper-error" v-if="!submited">{{
            Object.values(errors).flat()[0]
          }}</span>
          <md-button type="submit" class="md-success" :disabled="submited"
            >Finalizar
          </md-button>
        </md-card-actions>
      </md-card>
    </form>
  </ValidationObserver>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import servicios from "@/assets/json/servicios.json";
import nocriterios from "@/assets/json/nocriterios.json";

extend("required", {
  ...required,
  message: "* Campo requerido",
});

//https://stackoverflow.com/questions/47305082/setter-for-computed-property-obtained-from-store-in-vue-js
//helper function which generates the computed properties:
function mapStateTwoWay(...args) {
  const result = {};
  for (const prop of Object.keys(args[0])) {
    result[prop] = {
      get() {
        return this.$store.getters.getIcaDetById({
          uuid: this.currIca.uuid,
          param: prop,
        });
      },
      set(value) {
        if (typeof value === "string") value = value.toUpperCase();
        this.$store.commit(args[0][prop], {
          value: value,
          uuid: this.currIca.uuid,
          param: prop,
        });
      },
    };
  }
  return result;
}

export default {
  components: {
    SlideYDownTransition,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    unServicio: function() {
      return servicios?.[this.currIca.unidad_negocio];
    },
    ...mapStateTwoWay({
      A01: "setIcaDet",
      A02: "setIcaDet",
      A03: "setIcaDet",
      A04: "setIcaDet",
      A05: "setIcaDet",
      A06: "setIcaDet",
      A07: "setIcaDet",
      A08: "setIcaDet",
      A09: "setIcaDet",
      A10: "setIcaDet",
      A11: "setIcaDet",
      A12: "setIcaDet",
      A13: "setIcaDet",
      A14: "setIcaDet",
      A16: "setIcaDet",
      A17: "setIcaDet",
      A18: "setIcaDet",
      A01C: "setIcaDet",
      A02C: "setIcaDet",
      A03C: "setIcaDet",
      A04C: "setIcaDet",
      A05C: "setIcaDet",
      A06C: "setIcaDet",
      A07C: "setIcaDet",
      A08C: "setIcaDet",
      A09C: "setIcaDet",
      A10C: "setIcaDet",
      A11C: "setIcaDet",
      A12C: "setIcaDet",
      A13C: "setIcaDet",
      A14C: "setIcaDet",
      lugar_termino: "setIcaDet",
      comentarios: "setIcaDet",
      servicio: "setIcaDet",
    }),
  },
  data() {
    return {
      submited: false,
      saved: false,
      removec: [],
      currIca: {
        uuid: null,
        fecha_inicio_timestamp: null,
        placa_patente: null,
        estandar: null,
        unidad_negocio: null,
        tipo_veh: null,
      },
    };
  },
  created: function() {
    this.$options.location = { latitude: "", longitude: "" };
    this.$options.watchid = navigator.geolocation.watchPosition(
      (location) => {
        this.$options.location.latitude = location.coords.latitude;
        this.$options.location.longitude = location.coords.longitude;
      },
      () => {},
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 1 * 60 * 1000 }
    );
    this.currIcaM();
  },
  mounted: function() {
    //Debo eliminar los criterios que no aplican..
    //Verifico si el bus tiene condiciones a eliminar..
    //this.currIca. estandar y this.currIca.tipo_veh == en el json
    //Si tiene elimino del modelo las condiciones, vue va a actualizar de forma automatica la vista...
    //Solo debo eliminar del this.A01C
    //this.removec = nocriterios?.[this.currIca.estandar + this.currIca.tipo_veh];
    /*
    if (removec) {
      console.log(removec);
      for (const key of Object.keys(removec)) {
        console.log(key + " -> " + removec[key]);
      }
      //this.A11C = ["prueba"];

      //https://stackoverflow.com/questions/35594885/vuejs-disabled-selected-dropdown-options
      
    }
    */
  },
  beforeDestroy: function() {
    navigator.geolocation.clearWatch(this.$options.watchid);
    if (this.saved)
      this.$store.commit("clearIcaDet", { uuid: this.currIca.uuid });
  },
  watch: {
    "$route.params.uuid": function() {
      this.currIcaM();
    },
  },
  methods: {
    currIcaM() {
      const currIca = this.$store.getters.getIcaDetById({
        uuid: this.$route.params.uuid,
        param: null,
      });
      this.currIca = currIca;
      console.log(currIca.estandar);
      console.log(currIca.tipo_veh);

      this.removec = nocriterios?.[currIca.estandar + currIca.tipo_veh] || [];
    },
    submit() {
      const fecha_inicio_timestamp = this.currIca.fecha_inicio_timestamp;
      if (Date.now() / 1000 - fecha_inicio_timestamp < 15 * 60) {
        const min_pendiente = Math.ceil(
          (15 * 60 - (Date.now() / 1000 - fecha_inicio_timestamp)) / 60
        );
        return this.$notify({
          timeout: 2500,
          message: `En ${min_pendiente} minutos podrá finalizar`,
          icon: "add_alert",
          verticalAlign: "top",
          horizontalAlign: "center",
          type: "danger",
        });
      }
      this.submited = true;
      const payload = {
        uuid: this.currIca.uuid,
        fecha_fin: new Date().toLocaleDateString("en-GB", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }),
        hora_fin: new Date().toLocaleTimeString([], { hour12: false }),
        fecha_fin_timestamp: Date.now() / 1000,
        position_latitude_fin: this.$options.location.latitude,
        position_longitude_fin: this.$options.location.longitude,
        comentarios: this.comentarios,
        lugar_termino: this.lugar_termino,
        servicio: this.servicio,
        estado: 1,
        tipo_veh: this.currIca.tipo_veh,
        A01: this.A01,
        A02: this.A02,
        A03: this.A03,
        A04: this.A04,
        A05: this.A05,
        A06: this.A06,
        A07: this.A07,
        A08: this.A08,
        A09: this.A09,
        A10: this.A10,
        A11: this.A11,
        A12: this.A12,
        A13: this.A13,
        A14: this.A14,
        A16: this.A16,
        A17: this.A17,
        A18: this.A18,
        A01C: this.A01 ? [] : this.A01C,
        A02C: this.A02 ? [] : this.A02C,
        A03C: this.A03 ? [] : this.A03C,
        A04C: this.A04 ? [] : this.A04C,
        A05C: this.A05 ? [] : this.A05C,
        A06C: this.A06 ? [] : this.A06C,
        A07C: this.A07 ? [] : this.A07C,
        A08C: this.A08 ? [] : this.A08C,
        A09C: this.A09 ? [] : this.A09C,
        A10C: this.A10 ? [] : this.A10C,
        A11C: this.A11 ? [] : this.A11C,
        A12C: this.A12 ? [] : this.A12C,
        A13C: this.A13 ? [] : this.A13C,
        A14C: this.A14 ? [] : this.A14C,
      };
      this.$store.dispatch("medicaDet", payload);
      this.$notify({
        timeout: 1500,
        message: "Medición ICA Finalizada",
        icon: "add_alert",
        verticalAlign: "top",
        horizontalAlign: "center",
        type: "success",
      });
      this.saved = true;
      setTimeout(
        () =>
          this.$router.push({
            name: "medica",
          }),
        2000
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.md-helper-error {
  color: #f44336;
  font-size: 0.75rem;
  padding-right: 30px;
}
.md-layout-item {
  //padding-right: 0px;
  //padding-left: 0px;
}
.md-checkbox {
  margin: 0;
  margin-top: 5px;
  margin-bottom: 0.5rem;
}
</style>
